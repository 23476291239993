<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="is-hidden-desktop is-hidden-widescreen">
        <Mobilemenu />
      </div>
      <div class="columns">
        <div
          class="column is-narrow-desktop is-hidden-mobile is-hidden-touch sbar"
        >
          <Sidebar />
        </div>
        <div class="column">
          <div>
            <div class="is-flex is-justify-content-space-between block">
              <div>
                <h1 class="title">Waitlist</h1>
                <h2 class="subtitle">
                  Manage users on the waitlist
                </h2>
                <p>Users on the waitlist: {{ waitlist.length }}</p>
              </div>
              <input
                v-model="waitlistSearch"
                class="input"
                type="search"
                placeholder="Search waitlist"
                style="width: 25%"
              />
            </div>
            <div class="pagination">
              <div class="block">
                <div class="field is-grouped is-flex is-align-items-center">
                  <div class="is-flex is-align-items-center mr-2">
                    <span>Items per page:  </span>
                  </div>
                  <div class="field is-horizontal mb-0">
                    <div class="field-label"></div>
                    <div class="field-body">
                      <div class="field is-expanded">
                        <div class="field has-addons">
                          <p class="control is-expanded">
                            <input
                                v-model.number="tableItemsPerPage"
                                class="input"
                                placeholder="days"
                                type="number"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="is-flex is-align-items-center mx-2">
                    <span>Current page:  </span>
                  </div>
                  <div class="field is-horizontal mb-0">
                    <div class="field-label"></div>
                    <div class="field-body">
                      <div class="field is-expanded">
                        <div class="field has-addons">
                          <p class="control is-expanded">
                            <input
                                v-model.number="tableCurrentPage"
                                class="input"
                                placeholder="days"
                                type="number"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="control ml-2">
                    <button @click="changeTablePage(tableCurrentPage - 1)" :disabled="tableCurrentPage === 1" class="button is-primary mr-2">Prev</button>
                    <button @click="changeTablePage(tableCurrentPage + 1)" :disabled="tableCurrentPage >= paginatedWaitlistMaxPage" class="button is-primary">Next</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card block">
              <div class="card-content">
                <table
                  id="waitlistTable"
                  class="table is-fullwidth is-hoverable is-narrow"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th @click="changeSortBy('app')">App</th>
                      <th @click="changeSortBy('username')">Username</th>
                      <th @click="changeSortBy('date')">Date</th>
                      <th @click="changeSortBy('status')">Status</th>
                      <th @click="changeSortBy('code')">Code</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tfoot>
                    <tr>
                      <th>#</th>
                      <th @click="changeSortBy('app')">App</th>
                      <th @click="changeSortBy('username')">Username</th>
                      <th @click="changeSortBy('date')">Date</th>
                      <th @click="changeSortBy('status')">Status</th>
                      <th @click="changeSortBy('code')">Code</th>
                      <th>Actions</th>
                    </tr>
                  </tfoot>
                  <tbody>
                    <tr v-for="(user, i) in paginatedWaitlist" :key="user.id">
                      <th>{{ i + 1 }}</th>
                      <td>{{ user.chatApp }}</td>
                      <td>{{ user.username }}</td>
                      <td>{{ user.timestamp | date }}</td>
                      <td>{{ user.status }}</td>
                      <td>
                        <template v-if="user.code">{{ user.code }}</template>
                        <template v-else>
                          <button
                            @click="generateCode(user)"
                            class="button is-small is-success is-light"
                          >
                            Generate
                          </button>
                        </template>
                      </td>
                      <td>
                        <button
                          @click="deleteUser(user)"
                          class="button is-small is-danger is-light"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Mobilemenu from "@/components/Mobilemenu.vue";
import { db } from "@/firebase";

export default {
  name: "MembershipWaitlistView",
  components: {
    Sidebar,
    Mobilemenu,
  },
  data() {
    return {
      waitlist: [],
      waitlistSearch: null,
      tableCurrentPage: 1,
      tableItemsPerPage: 50,
      sortTableBy: "",
      sortTableDirection: "desc",
    };
  },
  firestore: {
    waitlist: db.collection("waitlist"),
  },
  filters: {
    date(val) {
      if (val) {
        return val.toDate().toLocaleString();
      } else {
        return val;
      }
    },
  },
  computed: {
    orderedWaitlist() {
      if (!this.waitlist) {
        return [];
      }
      if (this.sortTableBy === 'status') {
        if (this.sortTableDirection === 'asc') {
          return this.waitlist
              .map((x) => ({ ...x, id: x.id }))
              .filter((x) => !!x.status)
              .sort((a, b) => (a.status || "").localeCompare(b.status || ""));
        } else {
          return this.waitlist
              .map((x) => ({ ...x, id: x.id }))
              .filter((x) => !!x.status)
              .sort((a, b) => (b.status || "").localeCompare(a.status || ""));
        }
      } else if (this.sortTableBy === 'code') {
        if (this.sortTableDirection === 'asc') {
          return this.waitlist
              .map((x) => ({ ...x, id: x.id }))
              .filter((x) => !!x.code)
              .sort((a, b) => (a.code || "").localeCompare(b.code || ""));
        } else {
          return this.waitlist
              .map((x) => ({ ...x, id: x.id }))
              .filter((x) => !!x.code)
              .sort((a, b) => (b.code || "").localeCompare(a.code || ""));
        }
      } else if (this.sortTableBy === 'username') {
        if (this.sortTableDirection === 'asc') {
          return this.waitlist
              .map((x) => ({ ...x, id: x.id }))
              .filter((x) => !!x.username)
              .sort((a, b) => (a.username || "").localeCompare(b.username || ""));
        } else {
          return this.waitlist
              .map((x) => ({ ...x, id: x.id }))
              .filter((x) => !!x.username)
              .sort((a, b) => (b.username || "").localeCompare(a.username || ""));
        }
      } else if (this.sortTableBy === 'app') {
        if (this.sortTableDirection === 'asc') {
          return this.waitlist
              .map((x) => ({...x, id: x.id}))
              .filter((x) => !!x.chatApp)
              .sort((a, b) => (a.chatApp || "").localeCompare(b.chatApp || ""));
        } else {
          return this.waitlist
              .map((x) => ({...x, id: x.id}))
              .filter((x) => !!x.chatApp)
              .sort((a, b) => (b.chatApp || "").localeCompare(a.chatApp || ""));
        }
      } else {
        if (this.sortTableDirection === 'asc') {
          return this.waitlist
              .map((x) => ({ ...x, id: x.id }))
              .filter((x) => !!x.timestamp)
              .sort((a, b) => a.timestamp - b.timestamp);
        } else {
          return this.waitlist
              .map((x) => ({ ...x, id: x.id }))
              .filter((x) => !!x.timestamp)
              .sort((a, b) => b.timestamp - a.timestamp);
        }
      }
    },
    filteredWaitlist() {
      if (!this.waitlistSearch) {
        return this.orderedWaitlist;
      } else {
        return this.orderedWaitlist.filter((member) => {
          if (
            member.username &&
            member.username.includes(this.waitlistSearch)
          ) {
            return true;
          } else
            return !!(member.code && member.code.includes(this.waitlistSearch));
        });
      }
    },
    paginatedWaitlistMaxPage() {
      return Math.ceil(this.filteredWaitlist.length / this.tableItemsPerPage);
    },
    paginatedWaitlist() {
      // return new copy of array paginated
      return this.filteredWaitlist.slice(
          (this.tableCurrentPage - 1) * this.tableItemsPerPage,
          this.tableCurrentPage * this.tableItemsPerPage
      );
    },
  },
  methods: {
    changeSortBy(column) {
      if (this.sortTableBy === column) {
        this.sortTableDirection = this.sortTableDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortTableBy = column;
        this.sortTableDirection = "desc";
      }
    },
    logout() {
      this.$store.dispatch("logout");
    },
    deleteUser(user) {
      this.$firestoreRefs.waitlist.doc(user.id).delete();
    },
    generateCode(user) {
      const CHARS =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let code = "";
      for (let i = 0; i < 20; i++) {
        code += CHARS.charAt(Math.floor(Math.random() * CHARS.length));
      }
      this.$firestoreRefs.waitlist.doc(user.id).update({
        code,
      });
    },
    changeTablePage(page) {
      this.tableCurrentPage = page;
    },
  },
};
</script>
<style lang="scss" scoped>
#waitlistTable tr:hover {
  background-color: var(--app-background-color);
  transform: scale(1.02, 1.02);
}
</style>
