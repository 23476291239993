<template>
  <nav>
    <div class="container mobile">
      <input id="responsive-menu" type="checkbox" />
      <label for="responsive-menu">
        <img class="brand-wave" src="@/assets/logo_sq_bg.svg"/>
        <span> Rektproof </span>
        <span id="menu-icon"></span
      ></label>
      <div id="overlay"></div>
<!--      <template v-if="admin">-->
      <ul class="menu-label">
        RektProof Education
      </ul>
      <ul class="menu-list">
        <li>
          <router-link :to="{ name: 'EducationPDFLessons' }"
            >Lessons</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'KozHubVideos' }">Video Lessons</router-link>
        </li>
        <li v-if="admin">
          <router-link :to="{ name: 'EducationExams' }">Exams</router-link>
        </li>
      </ul>
      <ul class="menu-label">
        Membership
      </ul>
      <ul v-if="admin || staff" class="menu-list">
        <li>
          <router-link :to="{ name: 'MembershipPlans' }">Plans</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'MembershipMembers' }"
            >Members</router-link
          >
        </li>
        <li v-if="admin">
          <router-link :to="{ name: 'MembershipWaitlist' }"
            >Waitlist</router-link
          >
        </li>
      </ul>
      <template v-if="admin">
        <ul class="menu-label">
          Settings
        </ul>
        <ul class="menu-list">
          <li>
            <router-link :to="{ name: 'SettingsStaff' }">Staff</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'SettingsMembers' }">Members</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'SettingsWaitlist' }"
            >Waitlist</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'SettingsDiscount' }"
            >Discount</router-link
            >
          </li>
        </ul>
        <ul class="menu-label">
          Transactions
        </ul>
        <ul class="menu-list">
          <li>
            <router-link :to="{ name: 'TransactionsPayments' }"
            >BTC Payments</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'TransactionsPaymentsUSDT' }"
            >USDT Payments</router-link
            >
          </li>
        </ul>
      </template>
      <ul class="menu-label">
        Account
      </ul>
      <ul class="menu-list">
        <li>
          <router-link :to="{ name: 'AccountMyAccount' }"
            >My Account</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'AccountMyNotes' }">My Notes</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'AccountMyTradeJournals' }"
            >My Trade Journals</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'AccountCalculators' }"
          >Calculators</router-link
          >
        </li>
        <li><a @click="logout()">Log out</a></li>
      </ul>
<!--      </template>-->
<!--      <template v-else>-->
<!--        <ul class="menu-label">-->
<!--          RektProof Education-->
<!--        </ul>-->
<!--        <ul class="menu-list">-->
<!--          <li>-->
<!--            <router-link :to="{ name: 'EducationPDFLessons' }"-->
<!--              >Lessons</router-link-->
<!--            >-->
<!--          </li>-->
<!--          <li>-->
<!--            <router-link :to="{ name: 'KozHubVideos' }">Video Lessons</router-link>-->
<!--          </li>-->
<!--        </ul>-->
<!--        <ul class="menu-label">-->
<!--          Koz Hub-->
<!--        </ul>-->
<!--        <ul class="menu-list">-->
<!--          <li>-->
<!--            <router-link :to="{ name: 'KozHubVideos' }">Koz Videos</router-link>-->
<!--          </li>-->
<!--        </ul>-->
<!--        <ul class="menu-label">-->
<!--          Account-->
<!--        </ul>-->
<!--        <ul class="menu-list">-->
<!--          <li>-->
<!--            <router-link :to="{ name: 'AccountMyAccount' }"-->
<!--              >My Account</router-link-->
<!--            >-->
<!--          </li>-->
<!--          <li>-->
<!--            &lt;!&ndash; <div class="card-toggle">-->
<!--      <buttonDarkMode />-->
<!--    </div> &ndash;&gt;-->
<!--          </li>-->
<!--          <li>-->
<!--            <router-link :to="{ name: 'AccountMyNotes' }">My Notes</router-link>-->
<!--          </li>-->
<!--          <li>-->
<!--            <router-link :to="{ name: 'AccountMyTradeJournals' }"-->
<!--              >My Trade Journals</router-link-->
<!--            >-->
<!--          </li>-->
<!--          <li>-->
<!--            <router-link :to="{ name: 'AccountCalculators' }"-->
<!--            >Calculators</router-link-->
<!--            >-->
<!--          </li>-->
<!--          <li><a @click="logout()">Log out</a></li>-->
<!--        </ul>-->
<!--      </template>-->
    </div>
  </nav>
</template>
<script>
import { auth } from "@/firebase";
import ButtonDarkMode from "./buttonDarkMode.vue";
export default {
  data() {
    return {
      admin: false,
      staff: false
    };
  },
  async created() {
    const idTokenResult = await auth.currentUser.getIdTokenResult();
    // Confirm the user is an Admin.
    this.admin = !!idTokenResult.claims.admin;
    this.staff = !!idTokenResult.claims.staff;
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
  components: { ButtonDarkMode },
};
</script>
<style>
span {
  color: var(--text-primary-color);
}
</style>
