<template>
  <aside class="menu is-hidden-mobile is-hidden-touch">
    <p class="menu-label logo">
      <img class="brand-wave" src="@/assets/logo_sq_bg.svg" />
      <span>RektProof</span>
    </p>
    <p class="menu-label">
      RektProof Education
    </p>
    <ul class="menu-list">
      <li>
        <router-link :to="{ name: 'EducationPDFLessons' }"
          >Lessons</router-link
        >
      </li>
      <li>
        <router-link :to="{ name: 'KozHubVideos' }"
          >Video Lessons</router-link
        >
      </li>
      <li>
        <router-link :to="{ name: 'EducationExams' }">Exams</router-link>
      </li>
    </ul>
    <template v-if="admin || staff">
      <p class="menu-label">
        Membership
      </p>
      <ul class="menu-list">
        <li v-if="admin">
          <router-link :to="{ name: 'MembershipPlans' }">Plans</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'MembershipMembers' }">Members</router-link>
        </li>
        <li v-if="admin">
          <router-link :to="{ name: 'MembershipWaitlist' }"
            >Waitlist</router-link
          >
        </li>
      </ul>
    </template>
    <template v-if="admin">
      <p class="menu-label">
        Settings
      </p>
      <ul class="menu-list">
        <li>
          <router-link :to="{ name: 'SettingsStaff' }">Staff</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'SettingsMembers' }">Members</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'SettingsWaitlist' }">Waitlist</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'SettingsDiscount' }">Discount</router-link>
        </li>
      </ul>
      <p class="menu-label">
        Transactions
      </p>
      <ul class="menu-list">
        <li>
          <router-link :to="{ name: 'TransactionsPayments' }"
          >BTC Payments</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'TransactionsPaymentsUSDT' }"
          >USDT Payments</router-link
          >
        </li>
      </ul>
    </template>
    <p class="menu-label">
      Account
    </p>
    <ul class="menu-list">
      <li>
        <router-link :to="{ name: 'AccountMyAccount' }"
          >My Account</router-link
        >
      </li>
      <li>
        <router-link :to="{ name: 'AccountMyNotes' }">My Notes</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'AccountMyTradeJournals' }"
          >My Trade Journals</router-link
        >
      </li>
      <li>
        <router-link :to="{ name: 'AccountCalculators' }"
          >Calculators</router-link
        >
      </li>
      <li><a @click="logout()">Log out</a></li>
    </ul>
<!--    <template v-else>-->
<!--      <p class="menu-label">-->
<!--        RektProof Education-->
<!--      </p>-->
<!--      <ul class="menu-list">-->
<!--        <li>-->
<!--          <router-link :to="{ name: 'EducationPDFLessons' }"-->
<!--            >Lessons</router-link-->
<!--          >-->
<!--        </li>-->
<!--        <li>-->
<!--          <router-link :to="{ name: 'KozHubVideos' }"-->
<!--            >Video Lessons</router-link-->
<!--          >-->
<!--        </li>-->
<!--        <li>-->
<!--          <router-link :to="{ name: 'EducationExams' }">Exams</router-link>-->
<!--        </li>-->
<!--      </ul>-->
<!--      <p class="menu-label">-->
<!--        Account-->
<!--      </p>-->
<!--      <ul class="menu-list">-->
<!--        <li>-->
<!--          <router-link :to="{ name: 'AccountMyAccount' }"-->
<!--            >My Account</router-link-->
<!--          >-->
<!--        </li>-->
<!--        <li>-->
<!--          <router-link :to="{ name: 'AccountMyNotes' }">My Notes</router-link>-->
<!--        </li>-->
<!--        <li>-->
<!--          <router-link :to="{ name: 'AccountMyTradeJournals' }"-->
<!--            >My Trade Journals</router-link-->
<!--          >-->
<!--        </li>-->
<!--        <li>-->
<!--          <router-link :to="{ name: 'AccountCalculators' }"-->
<!--            >Calculators</router-link-->
<!--          >-->
<!--        </li>-->
<!--        <li><a @click="logout()">Log out</a></li>-->
<!--      </ul>-->
<!--    </template>-->
  </aside>
</template>
<script>
import { auth } from "@/firebase";
export default {
  data() {
    return {
      admin: false,
      staff: false
    };
  },
  async created() {
    const idTokenResult = await auth.currentUser.getIdTokenResult();
    // Confirm the user is an Admin.
    this.admin = !!idTokenResult.claims.admin;
    this.staff = !!idTokenResult.claims.staff;
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>
<style lang="scss" scoped>
.logo-text {
  color: var(--text-primary-color);
}
.menu-label {
  text-transform: uppercase;
  color: var(--text-primary-color);
}
.menu-list a {
  color: var(--text-tertiary-color);
  justify-content: space-around;
  max-width: 190px;
}
.menu-list a:hover {
  color: var(--text-primary-color);
  background-color: var(--sidebar-background-color-hover);
  transform: scale(1.02, 1.02);
  border-radius: 8px;
}
.menu-list a.is-active {
  color: var(--text-quarternary-color);
  background-color: var(--sidebar-background-color);
  transform: scale(1.02, 1.02);
  border-radius: 8px;
}
.logo {
  display: flex;
  align-content: center;
  font-family: "Work Sans";
  font-style: normal;
  text-transform: none;
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.1em;
  color: var(text-primary-color);
  span {
    margin-left: 8px;
  }
}
.card-toggle {
  margin-top: 25px;
}
.menu {
  max-width: 100%;
  flex-direction: column;
}
</style>
